App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

img.app-logo {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.MuiDrawer-paper {
  background-color: rgb(238, 238, 238) !important;
  /* background-color: #edf3f6 !important; */
}

.nav-link {
  text-decoration: none;
  color: black;
}

.nav-btn:hover {
  background-color: #e6ecff !important;
  border-radius: 10px;
  color: #003cff;
  font-weight: bold !important;
}

.nav-link-icon {
  /* color: #7f95dc; */
  width: 23px;
  font-weight: bold;
}

.nav-btn:hover .nav-link-icon {
  color: black;
}

.css-cveggr-MuiListItemIcon-root {
  min-width: 34px !important;
}

.RichTextEditor__root___2QXK- {
  height: 495px;
  overflow-y: scroll;
}

.demo {
  font-style: italic;
  color: rgb(160, 160, 160);
}

.new-users {
  background-color: red !important;
}
.logo {
  /* background-image: linear-gradient(to right bottom, #3bcdff, #65d1f5, #ceeefd); */
  /* background-image: linear-gradient(to right bottom, #f0f6b1, #8fe4da, #ceeefd); */
  background-image: linear-gradient(to left top, #e2f0c8, #b0e5f0, #3bcdff);

  /* url("./assets/cblifelogo.png"); */
  background-size: cover;
  background-position: center;
}
.pass:active {
  background-color: inherit;
}
.height {
  height: 500 !important;
  width: "10rem" !important;
}
.thead {
  /* background-color: #b0e5f0; */
  /* background-image: linear-gradient(to right bottom, #3bcdff, #9cdcf2, #ceeefd); */
  font-weight: 700;
}
.downloadTag {
  color: white;
  text-decoration: none;
}
